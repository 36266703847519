import { useMediaQuery } from "react-responsive";
import { phone, tablet } from "src/styles/media";

export const useAmSize = () => {
  const isTablet = useMediaQuery({query: tablet});
  const isPhone = useMediaQuery({query: phone});
  const componentsSize = isPhone ? 'small' : isTablet ? 'medium' : 'large';

  return componentsSize;
};

export default useAmSize;
