import { useContext } from 'react';
import addressManagementContext from '../../addressManagementModalContext';

const useAddressManagementModal = () => {
  const ctx = useContext(addressManagementContext);

  if (!ctx) {
    throw Error('addressManagementContext not wrapped by provider');
  }

  return ctx;
};

export default useAddressManagementModal;
