import { RetailChain } from 'src/api/types';
import { getChainsUrl } from 'src/api/urls';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';
import { fetchWithMemcached } from 'src/utils/ApiCacheClient/fetchWithMemcached';

export const requestGetRetailChains = async (language: string | undefined = undefined): Promise<RetailChain[]> => {
  const response = await fetchWithMemcached<RetailChain[]>({
    url: getChainsUrl(),
    fetchOptions: {
      language,
    },
    cacheKey: `retail-chains-${language}`,
  });

  return getDataFromResponse(response, []);
};
