import css from 'styled-jsx/css';

const styles =  css`
  .Skeleton {
    width: 100%;
    height: 100%;
    background-color: #eee;
    background: linear-gradient(to right, #f5f5f5 5%, #eee 20%, #f5f5f5 35%);
    animation: placeholderShimmer 4s linear 0s infinite normal forwards;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;

export default styles;
