import { QueryString } from '../../../api/types';
import { Delivery } from '../../delivery/types';

export const getRetailsInfoRequestQueryString = (userDelivery: Delivery | null): QueryString => {
  let queryString: QueryString = ``;

  if (userDelivery === null) {
    return queryString;
  }

  if (userDelivery.type === 'plan') {
    const coords = `${ userDelivery.geocodedAddress.coords.lat },${ userDelivery.geocodedAddress.coords.lng }`;

    queryString = `?include_delivery=1&coords=${ coords }&delivery_service_id=${ userDelivery.deliveryServiceId }`;
  }

  if (userDelivery.type === 'pickup') {
    queryString = `?include_delivery=1&delivery_service_id=${ userDelivery.deliveryServiceId }`;
  }

  if (userDelivery.type === 'nova_poshta') {
    const coords = `${ userDelivery.warehouse.coords.lat },${ userDelivery.warehouse.coords.lng }`;

    queryString = `?include_delivery=1&coords=${ coords }&settlement_id=${ userDelivery.settlement.id }`;
  }

  if (userDelivery.type === 'nova_poshta_address' && userDelivery.street.coords) {
    const coords = `${userDelivery.street.coords.lat},${userDelivery.street.coords.lng }`;

    queryString = `?include_delivery=1&coords=${ coords }&settlement_id=${ userDelivery.settlement.id }`;
  }

  return queryString;
};
