import { ReactNode } from 'react';
import RetailsInfoProvider from '../retails/context/RetailsInfoProvider';
import AddressManagementPreparerProvider from './hooks/useAddressManagementPreparer/AddressManagementPreparerProvider';

interface Props {
  children: ReactNode;
  language: string;
}

export default function AddressManagementRetailsProvider(props: Props) {
  const { language, children } = props;

  return (
    <AddressManagementPreparerProvider language={language}>
      <RetailsInfoProvider>
        {children}
      </RetailsInfoProvider>
    </AddressManagementPreparerProvider>
  );
}
