import { fetcher } from 'src/api/fetch';
import { getDataFromResponse } from 'src/utils/api/getDataFromResponse';
import { QueryString } from '../../../api/types';
import { Delivery } from '../../delivery/types';
import { UserRetail } from '../types';

export const requestGetUserRetails = async (
  deliveryType: Delivery['type'] | null,
  queryString: QueryString,
  language: string,
): Promise<UserRetail[]> => {
  const url = `/user/retail_chains/${queryString}`;

  const response = await fetcher<UserRetail[]>(url, {
    headers: {
      ...(deliveryType && { 'x-delivery-type': deliveryType }),
    },
    credentials: 'include',
    language,
  });

  return getDataFromResponse(response, []);
};
