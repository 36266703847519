import { useCallback, useMemo, useState } from 'react';
import { CoordsNumbers } from 'src/services/userDelivery/types';

const GEOLOCATION_TIMEOUT = 4000;

export interface UserGeolocationApi {
  geoCoords: CoordsNumbers | null;
  updateGeoCoords(): Promise<CoordsNumbers | null>;
  geoIsAvailable: boolean;
}

export default function useUserGeolocation(): UserGeolocationApi {
  const [geoCoords, setGeoCoords] = useState<CoordsNumbers | null>(null);
  const [geoIsAvailable, setGeoIsAvailable] = useState<boolean>(false);

  const updateGeoCoords = useCallback<() => Promise<CoordsNumbers | null>>(() => {
    return new Promise((resolve) => {
      if (window.navigator?.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          position => {
            const newCoords: CoordsNumbers = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setGeoCoords(newCoords);
            setGeoIsAvailable(true);
            resolve(newCoords);
          },
          () => {
            setGeoCoords(null);
            setGeoIsAvailable(false);
            resolve(null);
          },
          {
            timeout: GEOLOCATION_TIMEOUT,
          },
        );
      }
    });
  }, []);

  return useMemo(() => ({
    geoCoords,
    geoIsAvailable,
    updateGeoCoords,
  }), [geoCoords, geoIsAvailable, updateGeoCoords]);
}
