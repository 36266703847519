import { LocalizeFunc } from '../../services/localize/useLocalizeService';
import getDayOfWeekShortTranslationId from './getDayOfWeekShortTranslationId';
import getMonthShortTranslationId from './getMonthShortTranslationId';
import isToday from './isToday';
import isTomorrow from './isTomorrow';

const convertDateToString = (date: Date, localize: LocalizeFunc): string => {
  if (isToday(date)) {
    return localize('date_today');
  }

  if (isTomorrow(date)) {
    return localize('date_tomorrow');
  }

  const dayNumber = date.getDate();
  const shortMonthKey = getMonthShortTranslationId(date);
  const dayOfWeekKey = getDayOfWeekShortTranslationId(date);

  return `${dayNumber} ${localize(shortMonthKey)}, ${localize(dayOfWeekKey)}`;
};

export default convertDateToString;
