const shortMonthTranslationIdMap = {
  0: 'date_jan',
  1: 'date_feb',
  2: 'date_mar',
  3: 'date_apr',
  4: 'date_may',
  5: 'date_jun',
  6: 'date_jul',
  7: 'date_aug',
  8: 'date_sep',
  9: 'date_oct',
  10: 'date_nov',
  11: 'date_dec',
};

const getMonthShortTranslationId = (date: Date) => {
  return shortMonthTranslationIdMap[date.getMonth()];
};

export default getMonthShortTranslationId;
