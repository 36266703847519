import { useEffect, useState } from 'react';
import { fetcher } from 'src/api/fetch';
import { RequestStatus } from 'src/api/types';
import { DeliveryService } from '../../types';

export default function useDeliveryServices(language: string) {
  const [deliveryServices, setDeliveryServices] = useState<DeliveryService[]>();
  const [status, setStatus] = useState<RequestStatus>('idle');

  useEffect(() => {
    (async function () {
      setStatus('loading');

      const response = await fetchDeliveryServices(language);

      if (response.status === 'fail') {
        setStatus('failed');
      }

      if (response.status === 'success') {
        setDeliveryServices(response.data);
        setStatus('succeeded');
      }
    })();
  }, [language]);

  return {
    status,
    deliveryServices,
  };
}

export async function fetchDeliveryServices(language: string) {
  return await fetcher<DeliveryService[]>(`/delivery_services`, {
    language,
  });
}
