import { useContext } from 'react';
import addressManagementPreparerContext from './addressManagementPreparerContext';

export default function useAddressManagementPreparer() {
  const ctx = useContext(addressManagementPreparerContext);

  if (!ctx) {
    throw Error('useAddressManagementPreparer not wrapped by provider');
  }

  return ctx;
}
