import { getDeliveryTypeIsNP } from 'src/utils/delivery/novaPoshtaHelpers';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { Delivery } from '../../delivery/types';

export const getDeliveryTypeText = (userDelivery: Delivery | null, localize: LocalizeFunc): string => {
  if (userDelivery === null) {
    return '';
  }

  if (userDelivery.type === 'plan') {
    return localize('am_bar_plan');
  }

  if (userDelivery.type === 'pickup') {
    return localize('am_bar_pickup');
  }

  if (getDeliveryTypeIsNP(userDelivery.type)) {
    return localize('am_bar_np');
  }

  throw new Error('Unexpected delivery type');
};
