import { useServices } from "../../services";

const useUserDelivery = () => {
  const services = useServices();

  if (!services) {
    throw new Error('ServicesContext is used without provider in useUserDelivery');
  }

  return services.userDelivery;
};

export default useUserDelivery;
