const shortDayOfWeekTranslationIdMap = {
  0: 'date_Sun',
  1: 'date_Mon',
  2: 'date_Tue',
  3: 'date_Wed',
  4: 'date_Thu',
  5: 'date_Fri',
  6: 'date_Sat',
};

const getDayOfWeekShortTranslationId = (date: Date) => {
  return shortDayOfWeekTranslationIdMap[date.getDay()];
};

export default getDayOfWeekShortTranslationId;
