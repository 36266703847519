import { Delivery } from '../../delivery/types';

const getRetailInfoQueryStringForLink = (userDelivery: Delivery | null): string => {

  if (userDelivery === null) {
    return '';
  }

  return `delivery=${encodeURIComponent(JSON.stringify(userDelivery))}`;
};

export default getRetailInfoQueryStringForLink;
