import { RetailChain } from "src/api/types";

interface getRetailInfoLinkUrlParams {
  language: string;
  retailChain: RetailChain;
  withDeliveryData: boolean;
  deliveryAsQueryStringForLink: string;
  utmQueryString: string;
  specificRoute?: string;
}


const getRetailInfoLinkUrl = (params: getRetailInfoLinkUrlParams) => {
  const {
    language,
    retailChain,
    withDeliveryData,
    specificRoute = '',
    deliveryAsQueryStringForLink,
    utmQueryString,
  } = params;

  const basePath = `https://${retailChain.hostname}/${language}/${specificRoute}?${utmQueryString}`;

  if (withDeliveryData) {
    return `${basePath}&${deliveryAsQueryStringForLink}`;
  }

  return basePath;
};

export default getRetailInfoLinkUrl;
