import isToday from './isToday';

const isTomorrow = (date: Date) => {
  const cloneDate = new Date(date.getTime());

  cloneDate.setDate(cloneDate.getDate() - 1);

  return isToday(cloneDate);
};

export default isTomorrow;


