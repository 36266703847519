import truncateString from 'src/utils/js-helpers/truncateString';
import { LocalizeFunc } from '../../../services/localize/useLocalizeService';
import { Delivery, DeliveryService } from '../../delivery/types';

const ADDRESS_MAX_LENGTH = 26;

export function getDeliveryAddressText(
  userDelivery: Delivery | null,
  deliveryServices: DeliveryService[] | undefined,
  localize: LocalizeFunc,
): string | null {
  if (userDelivery === null) {
    return null;
  }

  if (userDelivery.type === 'plan') {
    const { city, street, building } = userDelivery.geocodedAddress;

    return `${ city }, ${ street }, ${ building }`;
  }

  if (userDelivery.type === 'pickup') {
    const deliveryService = deliveryServices?.find(ds => ds.id === userDelivery.deliveryServiceId);

    return deliveryService?.name || userDelivery.deliveryServiceId;
  }

  if (userDelivery.type === 'nova_poshta') {
    const npTypeText = userDelivery.warehouse.type === 'postomat' ? 'np_postomat' : 'np_department_short';

    const {
      settlement: {
        name,
      },
      warehouse: {
        number: departmentNumber,
      },
    } = userDelivery;

    return `${ name }, ${ localize(npTypeText) } №${ departmentNumber }`;
  }

  if (userDelivery.type === 'nova_poshta_address') {
    const {
      building,
      settlement: { name },
      street,
    } = userDelivery;

    const preparedLocality = truncateString(name, ADDRESS_MAX_LENGTH);
    const preparedStreet = truncateString(street.name, ADDRESS_MAX_LENGTH);

    return `${preparedLocality}, ${preparedStreet}, ${building}`;
  }

  throw new Error('Unexpected delivery type');
}
